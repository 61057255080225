import * as React from 'react';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
// import SaveIcon from '@mui/icons-material/Save';
import { ReactComponent as SaveIcon } from "../icons/save_item.svg";
import {
    GridRowModesModel,
    GridRowModes,
    GridColDef,
    GridActionsCellItem,
    GridEventListener,
    GridRowId,
    GridRowModel,
    GridValidRowModel,
    GridRowsProp,
    GridCellModesModel,
    GridCellParams,
    useGridApiRef,
    GridSortModel,
    GridSortDirection,
    GridTreeNode
} from '@mui/x-data-grid';
import { FC, ReactElement, useEffect, useState } from 'react';
import { randomId } from '@mui/x-data-grid-generator';
import { handleCellClickAsEditing, handleCellModesModelChange, handleRowClickAsEditing } from '../util/tableHandlers';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { StyledDataGrid } from './styledGrid';
import { TooltipIcon } from './tooltipIcon';
import ConfirmationPanel from './confirmationPanel';


interface CrudGridProps<R extends GridValidRowModel = any> {
    processRowUpdate?: (newRow: R, oldRow?: R) => Promise<R> | R;
    onDelete?: (item: R) => void;
    rows: GridRowsProp<R>;
    topColumns: readonly GridColDef<R>[];
    columns: readonly GridColDef<R>[];
    height: string;
    emptyRow: R;
    isCellEditable?: (params: GridCellParams<any, GridValidRowModel, GridValidRowModel, GridTreeNode>) => boolean;
    actionsHeaderKey?: string;
    extraActions?: (id: GridRowId, row?: R) => JSX.Element[];
    mandatoryColumnNames?: string[];
    placeholderRow?: R;
    modelType: string;
    sortField?: string;
    sortDirection?: GridSortDirection;
    updateSortModel?: (newSortModel: GridSortModel) => void;
}

const CrudGrid: FC<CrudGridProps> = (props: CrudGridProps): ReactElement => {
    const [changedRow, setChangedRow] = React.useState<GridRowId>(undefined);
    const [cellModesModel, setCellModesModel] = React.useState<GridCellModesModel>({});
    const [itemRows, setItemRows] = useState([]);
    const apiRef = useGridApiRef();

    const [topRows, setTopRows] = React.useState([props.placeholderRow ? { ...props.placeholderRow } : { ...props.emptyRow }]);
    const [topRowModesModel, setTopRowModesModel] = React.useState<GridRowModesModel>({ [props.emptyRow.id]: { mode: GridRowModes.Edit } });
    const { t } = useTranslation();

    //delete confirmation modal
    const [deleteItem, setDeleteItem] = useState<GridRowModel<GridValidRowModel>>(null);
    const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);

    //default sorting
    const [sortModel, setSortModel] = React.useState<GridSortModel>([
        {
            field: props.sortField,
            sort: props.sortDirection
        },
    ]);

    // Handle the displaying of the modal based on id
    const showDeleteModal = (id: GridRowId) => () => {
        const item = itemRows.find(r => r.id == id.toString());
        setDeleteItem(item);
        setDeleteConfirmationModal(true);
    };
    // Hide the modal
    const hideConfirmationModal = () => {
        setDeleteConfirmationModal(false);
    };
    // Handle the actual deletion of the item
    const submitDelete = () => {
        handleDeleteClick(deleteItem);
        setDeleteConfirmationModal(false);
    };

    useEffect(() => {
        if (props.rows) {
            setItemRows([...props.rows]);
        }
    }, [props.rows]);

    const translate = (col: GridColDef) => {
        return { ...col, headerName: t(col.headerName) };
    };

    const handleDeleteClick = (item: GridRowModel<GridValidRowModel>) => {
        props.onDelete(item);
        setItemRows(itemRows.filter((row) => row.id !== item.id));
    };

    const processItemRowUpdate = (newRow: GridRowModel<GridValidRowModel>, oldRow: GridRowModel<GridValidRowModel>) => {
        setChangedRow(undefined);
        if (newRow.id === props.emptyRow.id || newRow.id.startsWith('fake')) {
            console.info(`Returning on item upserted, this should not happen ${newRow.id}`);
        } else {
            props.processRowUpdate(newRow, oldRow);
        }
        return newRow;
    };

    const handleTopRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    }

    const handleTopRowSaveClick = (id: GridRowId) => () => {
        if (hasAllMandatoryValues(id)) {
            setTopRowModesModel({ [id]: { mode: GridRowModes.View } });
        }
    };

    const processTopRowUpdate = (newRow: GridRowModel<GridValidRowModel>) => {
        // This data gets added when checkboxes get clicked, we don't want it as new elements should have no data.
        const rowToSave = { ...newRow, id: undefined, isNew: true, data: undefined };
        props.processRowUpdate(rowToSave);
        // Note this is just to put the item in the lower grid, will be replaced by a proper row with the real ID when the app context updates
        setItemRows((oldRows) => {
            return [{ ...rowToSave, id: `fake-${randomId()}` }, ...oldRows]
        });
        setTopRows((_oldRows) => [{ ...props.emptyRow }]);
        return { ...newRow, isNew: false };
    };

    const handleTopRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setTopRowModesModel(newRowModesModel);
    };

    const getActions = (id: GridRowId, row: GridValidRowModel) => {
        const actions = [];
        if (props.extraActions) {
            props.extraActions(id, row).forEach((element) => {
                actions.push(element);
            });
        }
        if (props.onDelete) {
            actions.push(
                <GridActionsCellItem
                    icon={<TooltipIcon titleKey={'general.delete'} icon={<DeleteIcon />} />}
                    label="Delete"
                    onClick={showDeleteModal(id)}
                    sx={{
                        color: 'primary.main',
                    }}
                />
            );
        }
        return actions;
    }

    const editableTableColumns: GridColDef[] = (props.extraActions || props.onDelete ?
        [
            ...props.columns,
            {
                field: 'actions',
                type: 'actions',
                width: 200,
                headerName: props.actionsHeaderKey ?? 'meeting.actions',
                cellClassName: 'actions',
                getActions: ({ id, row }) => getActions(id, row)
            },
        ] :
        props.columns).map(translate);

    const hasMandatoryValue = (id, field) => {
        const row = apiRef.current.getRowWithUpdatedValues(id, field);
        if (!row[field]) {
            return false;
        }
        return true;
    }

    const hasAllMandatoryValues = (id) => {
        if (props.mandatoryColumnNames) {
            for (const name of props.mandatoryColumnNames) {
                if (!hasMandatoryValue(id, name)) {
                    return false;
                }
            }
        }
        return true;
    }

    const topColumnsWithMandatoryStyles = props.topColumns.map(column => {
        if (props.mandatoryColumnNames?.includes(column.field)) {
            return {
                ...column,
                cellClassName: (params: GridCellParams<any, number>) => {
                    const { id, field } = params;
                    const rowMode = apiRef.current.getRowMode(id);
                    if (rowMode === 'edit') {
                        if (!hasMandatoryValue(id, field)) {
                            return 'required-field';
                        }
                    }
                    return '';
                }
            };
        }
        return column;
    })

    const topRowColumns: GridColDef[] =
        [...topColumnsWithMandatoryStyles,
        {
            field: 'actions',
            type: 'actions',
            width: 200,
            headerName: 'meeting.save',
            cellClassName: 'actions',
            getActions: (params) => {
                // console.log("params: " + JSON.stringify(params));
                return [
                    <GridActionsCellItem
                        icon={<TooltipIcon titleKey={'general.save'} icon={<SaveIcon/>}/>}
                        label="Save"
                        //save is disabled if no meeting name or item content is provided
                        // disabled={!hasAllMandatoryValues(params.id)}
                        sx={{
                            color: 'primary.main',
                        }}
                        onClick={handleTopRowSaveClick(params.id)}
                    />,
                ];

            },
        },
        ].map(translate);

    function updateSortModel(newSortModel: GridSortModel) {
        // console.log("Current sort field: " + sortModel[0]?.field);
        // console.log("Current sort direction: " + sortModel[0]?.sort);
        // console.log("New sort field: " + newSortModel[0]?.field);
        // console.log("New sort direction: " + newSortModel[0]?.sort);
        setSortModel(newSortModel);
        if (props.updateSortModel) {
            props.updateSortModel(newSortModel);
        }
    }

    return (

        <div style={{
            height: props.height, width: '100%',
            display: 'flex', flexDirection: 'column', rowGap: 5, marginTop: 5
        }}>
            <Box
                sx={{
                    backgroundColor: "background.default",
                    height: 75, width: '100%',
                    '& .required-field': {
                        // border: '2px solid #f8bbd0 !important',
                        // backgroundColor: '#fce4ec !important'
                        borderRadius: 2,
                        border: '1px solid #f8bbd0 !important',
                        backgroundColor: '#fff !important'
                    }
                }}>
                <StyledDataGrid
                    apiRef={apiRef}
                    rows={topRows}
                    columns={topRowColumns}
                    editMode="row"
                    rowModesModel={topRowModesModel}
                    onRowModesModelChange={React.useCallback(handleTopRowModesModelChange, [])}
                    onRowClick={React.useCallback(handleRowClickAsEditing(setTopRowModesModel), [])}
                    onRowEditStop={handleTopRowEditStop}
                    processRowUpdate={processTopRowUpdate}
                    isCellEditable={props.isCellEditable}
                    hideFooter={true}
                    // getRowHeight={() => 73}
                    rowHeight={73}
                    //needed for alignment of columns in top and bottom grids
                    // scrollbarSize={0}
                    autoHeight={true}
                    slots={{
                        columnHeaders: () => null,

                    }}
                    getRowClassName={(params) => {
                        return 'top-row-theme'
                    }}
                // sx={{
                //     '& .MuiDataGrid-row--editing': {
                //         boxShadow: 'none',
                //         backgroundColor: 'background.default'
                //     }
                // }}
                />
            </Box>

            <Box
                sx={{
                    backgroundColor: "background.default",
                    height: 'calc(100% - 75px)', width: '100%'
                }}>
                <StyledDataGrid
                    pageSizeOptions={[1000]}
                    rows={itemRows}
                    columns={editableTableColumns}
                    cellModesModel={cellModesModel}
                    onCellModesModelChange={React.useCallback(handleCellModesModelChange(setCellModesModel), [])}
                    onCellClick={React.useCallback(handleCellClickAsEditing(setCellModesModel, setChangedRow), [])}
                    processRowUpdate={processItemRowUpdate}
                    isCellEditable={props.isCellEditable}
                    rowHeight={73}
                    // getRowHeight={() => 'auto'}
                    //needed for alignment of columns in top and bottom grids
                    // scrollbarSize={0}
                    showColumnVerticalBorder={true}
                    getRowClassName={(params) => {
                        return 'grid-theme'
                    }}
                    sortModel={sortModel}
                    onSortModelChange={(newSortModel) => updateSortModel(newSortModel)}
                // sx={{overflow: 'hidden'}}
                />
            </Box>
            {deleteConfirmationModal &&
                <ConfirmationPanel
                    showModal={deleteConfirmationModal}
                    confirmModal={submitDelete}
                    hideModal={hideConfirmationModal}
                    entry={deleteItem}
                    type={props.modelType}
                    action="delete"
                />}
        </div>
    );
}
export default CrudGrid;