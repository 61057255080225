import { GridActionsCellItem, GridCellParams, GridColDef, GridRowId, GridRowModel, GridSortDirection, GridSortModel } from '@mui/x-data-grid';
import { withApplicationInsights } from "../../components/telemetry";
import { useContext, useEffect, useMemo, useState } from 'react';
import * as dashboardActions from '../../actions/creatorDashboardActions';
import TaskTimeline from '../../components/taskTimeline';
import dateFormatter from '../../util/dateFormatter';
import { useNavigate } from 'react-router-dom';
import { MeetingItemRepresentation, categoryColumn, contentColumn, convertToRepresentation, createdDateColumn, dueDateColumn, getMeetingItemFromRepresentation, hasRepresentationChanged, isStatusUpdated, meetingNameColumn, statusColumn, statusTranslatedLabelOptions } from '../../models/meetingItemRepresentation';
import EditableGrid from '../../components/editableGrid';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { AttachmentsPane } from '../../components/attachmentsPane';
import ItemStatus from '../../components/itemStatus';
import config from '../../config';
import { useTranslation } from 'react-i18next';
import { Badge, Tooltip, useTheme } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import CustomToolbar from '../../components/customToolbar';
import { MeetingItem, MeetingItemStatus } from '../../models';
import { bindActionCreators } from '../../actions/actionCreators';
import { ItemActions } from '../../actions/itemActions';
import { AppContext } from '../../models/applicationState';
import { Context } from '../../components/context';
import * as itemActions from '../../actions/itemActions';
import { TooltipIconWithBadge } from '../../components/tooltipIconWithBadge';
import { ListActions } from '../../actions/listActions';
import * as listActions from "../../actions/listActions";
import { ItemEditingPanel, addStatusUpdateSystemCommentToItem, hasUserComments } from '../../components/itemEditingPanel';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import { ReactComponent as MeetingAttachment } from "../../icons/meeting_attachment.svg";
import { useMsal } from '@azure/msal-react';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { TooltipIcon } from '../../components/tooltipIcon';

const CreatorDashboard = () => {
    const CREATOR_SORT_FIELD = "creator.sortField";
    const CREATOR_SORT_DIRECTION = "creator.sortDirection";
    const HIGHLIGHTED_ITEMS_KEY = "creatorHighlightedItems"
    const navigate = useNavigate();
    const [items, setItems] = useState<MeetingItemRepresentation[]>([]);
    const [gridItems, setGridItems] = useState<MeetingItemRepresentation[]>([]);
    const [showCompleted, setShowCompleted] = useState(false);
    const [showInactive, setShowInactive] = useState(false);
    const [highlightedItemIds, setHghlightedItemIds] = useState<string[]>(JSON.parse(window.sessionStorage.getItem(HIGHLIGHTED_ITEMS_KEY)));
    const [timelineInterval, setTimelineInterval] = useState<number>(parseInt(window.localStorage.interval) || 30);
    const [showAttachmentsForMeeting, setShowAttachmentsForMeeting] = useState(undefined);
    const [showAttachmentsForItem, setShowAttachmentsForItem] = useState(undefined);
    const { t } = useTranslation();
    const apiRef = useGridApiRef();
    const appContext = useContext<AppContext>(Context);
    const [selectedProjectFilters, setSelectedProjectFilters] = useState(appContext.state.selectedProjectFilters);
    const [showEditingPanelForItem, setShowEditingPanelForItem] = useState(undefined);
    const { instance } = useMsal();

    const actions = useMemo(() => ({
        items: bindActionCreators(itemActions, appContext.dispatch) as unknown as ItemActions,
        lists: bindActionCreators(listActions, appContext.dispatch) as unknown as ListActions,
    }), [appContext.dispatch]);

    useEffect(() => {
        if (selectedProjectFilters !== appContext.state.selectedProjectFilters) {
            setSelectedProjectFilters(appContext.state.selectedProjectFilters);
            fetchData();
        }
    }, [appContext.state.selectedProjectFilters, selectedProjectFilters, navigate]);

    const startInterval = (): string => {
        const today = new Date();
        return dateFormatter.format(today.setDate(today.getDate() - timelineInterval));
    }
    const endInterval = (): string => {
        const today = new Date();
        return dateFormatter.format(today.setDate(today.getDate() + timelineInterval));
    }

    useEffect(() => {
        // update the column when presets change
        apiRef.current.updateColumns([progressColumn]);
    }, [timelineInterval]);

    useEffect(() => {
        const existingStorage = window.sessionStorage.getItem(HIGHLIGHTED_ITEMS_KEY);
        if (existingStorage) {
            const existingHighlightedItems = JSON.parse(existingStorage) as string[];
            if (existingHighlightedItems?.length !== highlightedItemIds?.length) {
                const stringToStore = JSON.stringify(highlightedItemIds);
                window.sessionStorage.setItem(HIGHLIGHTED_ITEMS_KEY, stringToStore);
            }
        } else {
            window.sessionStorage.setItem(HIGHLIGHTED_ITEMS_KEY, JSON.stringify(highlightedItemIds));
        }
    }, [highlightedItemIds]);

    useEffect(() => {
        let itemsToShow = items;
        if (!showInactive) {
            itemsToShow = itemsToShow.filter(i => i.status !== MeetingItemStatus.OnHold && i.status !== MeetingItemStatus.Cancelled);
        }
        if (!showCompleted) {
            itemsToShow = itemsToShow.filter(i => i.status !== MeetingItemStatus.Completed);
        }
        setGridItems(itemsToShow);
    }, [showCompleted, showInactive, items]);

    async function fetchData() {
        if (!showAttachmentsForMeeting && !showAttachmentsForItem) {
            const response = await dashboardActions.list();
            const converted = convertToRepresentation(response).filter(i => !i.isSnapshot && i.task);
            setItems(converted);
        }
    }

    const onArchived = (archived: boolean) => {
        onArchivedAsync(archived);
    }

    const onArchivedAsync = async (archived: boolean) => {
        if (!showAttachmentsForMeeting && !showAttachmentsForItem) {
            const response = await dashboardActions.list({ archived });
            const converted = convertToRepresentation(response).filter(i => !i.isSnapshot && i.task);
            setItems(converted);
        }
    };

    const onCompleted = (completed: boolean) => {
        onCompletedAsync(completed);
    }

    const onCompletedAsync = async (completed: boolean) => {
        setShowCompleted(completed);
    };

    const onInactive = (inactive: boolean) => {
        onInactiveAsync(inactive);
    }

    const onInactiveAsync = async (inactive: boolean) => {
        setShowInactive(inactive);
    };

    const onItemDeleted = (itemRepresentation: MeetingItemRepresentation) => {
        const item = getMeetingItemFromRepresentation(itemRepresentation);
        if (item.id) {
            actions.items.remove(itemRepresentation.meetingId, item);
        }
    }

    const openItemEditingPanel = (item: MeetingItemRepresentation) => () => {
        setShowEditingPanelForItem({ item });
    };

    const onItemUpdated = async (itemRepresentation: MeetingItemRepresentation) => {
        const statusUpdated = isStatusUpdated(itemRepresentation);
        if (statusUpdated) {
            const userFullName = (appContext.state.invoker.lastName && appContext.state.invoker.firstName) ? (appContext.state.invoker?.firstName + ' ' + appContext.state.invoker?.lastName) : instance.getActiveAccount()?.name;
            addStatusUpdateSystemCommentToItem(itemRepresentation, userFullName, t)
        }
        const item = getMeetingItemFromRepresentation(itemRepresentation);
        setItems(items.map((row) => (row.id === itemRepresentation.id ? itemRepresentation : row)));
        onItemUpserted(item);
    }

    async function executeCallbackIfDiffsExist(callback?: () => Promise<void>) {
        if (!showAttachmentsForMeeting && !showAttachmentsForItem) {
            const response = await dashboardActions.listDiffs();
            if (response.length) {
                const highlightsToStore = highlightedItemIds?.length ? [...highlightedItemIds] : [];
                const newHighlightedIds = response.map(i => i.id);
                if (newHighlightedIds) {
                    for (const highlightedItemId of newHighlightedIds) {
                        if (!highlightsToStore.includes(highlightedItemId)) {
                            highlightsToStore.push(highlightedItemId);
                        }
                    }
                    setHghlightedItemIds(highlightsToStore);
                }
                if (callback) {
                    callback();
                }
            }
        }
    }

    useEffect(() => {
        executeCallbackIfDiffsExist();
        // Always fetch data on the initial load
        fetchData();
        const interval = setInterval(() => {
            executeCallbackIfDiffsExist(fetchData);
        }, 10000);
        return () => clearInterval(interval);
    }, [navigate, showAttachmentsForMeeting, showAttachmentsForItem]);

    const openMeetingAttachments = (meetingId: string) => () => {
        setShowAttachmentsForMeeting(meetingId);
    };

    const openItemAttachments = (id: GridRowId, meetingId: string) => () => {
        setShowAttachmentsForItem({ id, meetingId });
    };

    const promptFeedback = (item: MeetingItemRepresentation) => () => {

        const subject = `${t('mail_template.request_feedback_subject', { task: item.identifier })}`;
        const bodyContent = `${t('mail_template.request_feedback_content',
            {
                taskId: item.identifier,
                meetingName: item.meetingName,
                baseUrl: config.web.baseUrl,
                creator: item.creator
            })}`;

        const mailtoUrl = `mailto:${item.actor}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(bodyContent)}`;

        window.location.href = mailtoUrl;
    };

    const onItemUpserted = async (item: MeetingItem) => {
        return await actions.items.save(item.meetingId, item);
    }

    const processRowUpdate = (newRow: GridRowModel<MeetingItemRepresentation>) => {
        if (!hasRepresentationChanged(newRow)) {
            return newRow;
        }
        const statusUpdated = isStatusUpdated(newRow);
        if (statusUpdated) {
            const userFullName = (appContext.state.invoker.lastName && appContext.state.invoker.firstName) ? (appContext.state.invoker?.firstName + ' ' + appContext.state.invoker?.lastName) : instance.getActiveAccount()?.name;
            addStatusUpdateSystemCommentToItem(newRow, userFullName, t)
        }
        newRow.data = getMeetingItemFromRepresentation(newRow);
        setItems(items.map((row) => (row.id === newRow.id ? newRow : row)));
        onItemUpserted(newRow.data);
        return newRow;
    };

    const onMeetingSelected = async (meetingId: string) => {
        actions.lists.load(meetingId);
    }

    const onNavigate = (meetingId: string) => {
        if (meetingId) {
            onMeetingSelected(meetingId);
            navigate(`/library/${meetingId}/current`);
        }
    };

    const handleClickNavigation = (item: MeetingItemRepresentation) => () => {
        onNavigate(item?.meetingId);
    };

    const progressColumn: GridColDef<any> = {
        field: 'progress', headerName: `${t('dashboard.progress', { from: startInterval(), to: endInterval() })}`, align: 'left', headerAlign: 'left',
        width: 350,
        type: 'actions',
        // filterable: true,
        renderCell: (params: GridCellParams) => (
            <TaskTimeline status={params.row.status}
                dueDate={params.row.dueDate}
                previousDueDates={params.row.previousDueDates} />
        ),
    }
    
    const columns: GridColDef[] = [
        createdDateColumn,
        { ...meetingNameColumn, width: 150},
        categoryColumn,
        {...contentColumn, flex: 0.6},
        { field: 'actor', headerName: 'item.actor', editable: false },
        dueDateColumn,
        {
            ...statusColumn, editable: true,
            type: 'singleSelect',
            valueFormatter: (value: string) => t(`item_status.${value}`),
            valueOptions: statusTranslatedLabelOptions(t),
            renderCell: (params: GridCellParams) => (
                <ItemStatus status={params.row.status} lastModifiedBy={params.row.statusLastUpdatedBy}></ItemStatus>
            ),
        },
        progressColumn,
        // meetingAttachmentsColumn,
        // itemAttachmentsColumn
    ];

    const onArchiveToggle = (itemRepresentation: MeetingItemRepresentation) => {
        itemRepresentation.archived = itemRepresentation.archived ? false : true;
        const item = getMeetingItemFromRepresentation(itemRepresentation);
        setItems(items.filter((row) => (row.id !== itemRepresentation.id)));
        onItemUpserted(item);
    };

    const toggleArchive = (itemRepresentation: MeetingItemRepresentation) => () => {
        onArchiveToggle(itemRepresentation);
    };

    const extraActions = (id: GridRowId, row: MeetingItemRepresentation) => {
        return ([
            <GridActionsCellItem
                icon={<TooltipIconWithBadge titleKey={'general.open'} icon={<ChevronRightIcon />} badgeContent={hasUserComments(row) ? "i" : undefined} />}
                label="Edit"
                onClick={openItemEditingPanel(row)}
                sx={{
                    color: 'primary.main',
                }}
            />,
            <GridActionsCellItem
                    icon={
                        <TooltipIconWithBadge
                            badgeContent={row.meetingAttachments}
                            titleKey='dashboard.meetingAttachments'
                            icon={<MeetingAttachment/>}
                        />
                    }
                    label={t('dashboard.meetingAttachments_label')}
                    onClick={openMeetingAttachments(row.meetingId)}
                    sx={{
                        color: 'primary.main',
                    }}
                />,
                <GridActionsCellItem
                    icon={
                        <TooltipIconWithBadge
                            badgeContent={row.attachments}
                            titleKey='dashboard.itemAttachments'
                            icon={< AttachFileIcon />}
                        />
                    }
                    label={t('dashboard.itemAttachments_label')}
                    onClick={openItemAttachments(id, row.meetingId)}
                    sx={{
                        color: 'primary.main',
                    }}
                />,
            <GridActionsCellItem
                icon={
                    <Tooltip title={t('meeting.open')}>
                        <EventNoteOutlinedIcon fontSize='medium'/>
                    </Tooltip>
                }
                label={t('meeting.open')}
                sx={{
                    color: 'primary.main',
                }}
                onClick={handleClickNavigation(row)}
            />,
            <GridActionsCellItem
                        icon={<TooltipIcon titleKey={row.archived ? 'general.unarchive' : 'general.archive'} icon={ row.archived ? <UnarchiveIcon /> : <ArchiveOutlinedIcon />} />}
                        label="Archive"
                        onClick={toggleArchive(row)}
                        sx={{
                            color: 'primary.main',
                        }}
                    />,
        ]);
    }

    async function updateSortModel(newSortModel: GridSortModel) {
        localStorage.setItem(CREATOR_SORT_FIELD, newSortModel[0]?.field);
        localStorage.setItem(CREATOR_SORT_DIRECTION, newSortModel[0]?.sort);
    }


    return (
        <Box>
            <CssBaseline />
            {showAttachmentsForMeeting
                && (
                    <AttachmentsPane
                        allowModifications={true}
                        height='70vh'
                        uploadPrefix={`meetings/${showAttachmentsForMeeting}/`}
                        meetingId={showAttachmentsForMeeting}
                        onClose={() => setShowAttachmentsForMeeting(undefined)}
                        onAttachmentsCountChanged={(count, meetingId, _itemId) => setItems(items.map(item => item.meetingId === meetingId ? { ...item, meetingAttachments: count } : item))}
                    />
                )}
            {showAttachmentsForItem
                && (
                    <AttachmentsPane
                        allowModifications={true}
                        height='70vh'
                        uploadPrefix={`items/meetings/${showAttachmentsForItem.meetingId}/items/${showAttachmentsForItem.id}/`}
                        meetingId={showAttachmentsForItem.meetingId}
                        itemId={showAttachmentsForItem.id}
                        onClose={() => setShowAttachmentsForItem(undefined)}
                        onAttachmentsCountChanged={(count, _meetingId, itemId) => setItems(items.map(item => item.id === itemId ? { ...item, attachments: count } : item))}
                    />
                )}
            {showEditingPanelForItem
                && (
                    <ItemEditingPanel meetingItem={showEditingPanelForItem.item} open={showEditingPanelForItem} onClose={() => setShowEditingPanelForItem(undefined)} onUpdate={onItemUpdated}
                    />
                )}
            <EditableGrid
                height='90vh'
                processRowUpdate={processRowUpdate}
                rows={gridItems}
                columns={columns}
                sortField={localStorage.getItem(CREATOR_SORT_FIELD)}
                sortDirection={localStorage.getItem(CREATOR_SORT_DIRECTION) as GridSortDirection}
                updateSortModel={updateSortModel}
                apiRef={apiRef}
                toolbar={CustomToolbar}
                onDelete={onItemDeleted}
                modelType={'item'}
                extraActions={extraActions}
                toolbarProps={
                    {
                        toolbar: {
                            showSettings: true,
                            interval: timelineInterval,
                            updateInterval: setTimelineInterval,
                            onArchived: onArchived,
                            onCompleted: onCompleted,
                            onInactive: onInactive,
                            updateColumns: [progressColumn]
                        }
                    }
                }
            />
        </Box>
    );
};

export default withApplicationInsights(CreatorDashboard, "MyDashboard");
