import { useContext, useEffect, useMemo, useState } from "react";
import React from "react";
import { AppContext } from "../../models/applicationState";
import { Box, Button, IconButton, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import ClosedCaptionIcon from '@mui/icons-material/ClosedCaption';
import CancelIcon from '@mui/icons-material/Cancel';
import { DataGrid, GridActionsCellItem, GridColDef, GridRenderCellParams, GridRowId, GridRowModel, GridTreeNodeWithRender, GridValidRowModel } from "@mui/x-data-grid";
import { ApiUser, Meeting, MeetingStatus, UserRole } from "../../models";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import config from "../../config";
import { MeetingHeader } from "../../components/meetingHeader";
import { Context } from "../../components/context";
import { bindActionCreators } from "../../actions/actionCreators";
import { ListActions } from "../../actions/listActions";
import * as listActions from "../../actions/listActions";
import { useTranslation } from "react-i18next";
import { bulkCreateUsers } from "../../services/userService";
import { useNavigate, useParams } from "react-router-dom";
import { ItemActions } from "../../actions/itemActions";
import * as itemActions from "../../actions/itemActions";
import { addPublishSystemCommentToItem } from "../../components/itemEditingPanel";
import { useMsal } from "@azure/msal-react";
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { TooltipIcon } from "../../components/tooltipIcon";

export interface ParticipantDisplayInfo {
    id: string;
    email: string;
    notification: string;
}

const getAllMeetingEmails = (meeting: Meeting): string[] => {
    const allEmails = [];
    for (const email of [
        ...(meeting?.participants || []),
        ...(meeting?.viewers || []),
        ...(meeting?.items?.map(item => item.actor) || [])

    ]) {
        if (email && !allEmails.includes(email)) {
            allEmails.push(email);
        }
    }
    return allEmails;
}
const createTable = (meeting: Meeting): ParticipantDisplayInfo[] => {
    const allEmails = meeting?.status === MeetingStatus.Published ? meeting.viewers : getAllMeetingEmails(meeting);
    return allEmails ? allEmails.map(email => ({
        id: email,
        email: email,
        notification: "to"
    })) : [];
};

export const ReviewPage = () => {
    // const { meetingId } = useParams();
    const appContext = useContext<AppContext>(Context);
    const [showEmailDistributionChoice, setShowEmailDistributionChoice] = useState(undefined);
    const { instance } = useMsal();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { meetingId } = useParams();
    const actions = useMemo(
        () => ({
            lists: bindActionCreators(
                listActions,
                appContext.dispatch
            ) as unknown as ListActions,
            items: bindActionCreators(
                itemActions,
                appContext.dispatch
            ) as unknown as ItemActions,
        }),
        [appContext.dispatch]
    );
    const [items, setItems] = useState<ParticipantDisplayInfo[]>(createTable(appContext.state.selectedList));
    const [newParticipant, setNewParticipant] = useState("");
    
    const processRowUpdate = (newRow: GridRowModel<ParticipantDisplayInfo>) => {
        const newItems = items.map(item => item.id === newRow.id ? newRow : item);
        if (appContext.state.selectedList?.status === MeetingStatus.Published) {
            publishMeetingWithUsers(newItems, MeetingStatus.Published);
        }
        return newRow;
    };

    const [publishText, setPublishText] = useState('button.publish');

    useEffect(() => {
        if (meetingId && appContext.state.selectedList?.id !== meetingId) {
            actions.lists.load(meetingId);
        }
        setPublishText(appContext.state.selectedList?.status === MeetingStatus.Published ? 'button.published' : 'button.publish');
        const itemsOnList = createTable(appContext.state.selectedList);
        setItems(itemsOnList);
    }, [actions.lists, appContext.state.selectedList, appContext.state.selectedList?.items, meetingId, navigate]);

    // Load items for selected list
    useEffect(() => {
        if (appContext.state.selectedList?.id && !appContext.state.selectedList.items) {
            const loadListItems = async (listId: string) => {
                await actions.items.list(listId);
            }
            loadListItems(appContext.state.selectedList.id)
        }
    }, [actions.items, appContext.state.selectedList?.id, appContext.state.selectedList?.items, navigate])

    const translate = (col: GridColDef): GridColDef => {
        return { ...col, headerName: t(col.headerName) };
    };

    const publishMeetingWithUsers = async (usersInput: ParticipantDisplayInfo[], newMeetingStatus: MeetingStatus) => {
        const meeting = appContext.state.selectedList;
        const oldStatus = meeting.status;
        const viewers = usersInput.filter(item => item.notification === "to" || item.notification === "cc").map(item => item.email);
        meeting.viewers = viewers;
        meeting.status = newMeetingStatus;
        actions.lists.save(meeting);

        if (appContext.state.selectedList?.items && oldStatus !== MeetingStatus.Published) {
            const userFullName = (appContext.state.invoker.lastName && appContext.state.invoker.firstName) ? (appContext.state.invoker?.firstName + ' ' + appContext.state.invoker?.lastName) : instance.getActiveAccount()?.name;
            await Promise.all(appContext.state.selectedList.items.map(item => {
                addPublishSystemCommentToItem(item, userFullName, t);
                return actions.items.save(appContext.state.selectedList.id, item);
            }
            ));
        }

        const users: ApiUser[] = usersInput.map(u => ({ email: u.email, role: UserRole.Actor }));
        await bulkCreateUsers(users);
    }

    const publishAsync = async (sendEmail: boolean) => {
        await publishMeetingWithUsers(items, MeetingStatus.Published);
        if (!sendEmail) {
            return;
        }
        const toUsers = items.filter(item => item.notification == "to");
        const ccUsers = items.filter(item => item.notification == "cc");
        if (toUsers.length > 0 || ccUsers.length > 0) {
            const toList = toUsers.map(item => item.email).join(';');
            const ccList = ccUsers.map(item => item.email).join(';');

            const subject = t('mail_template.meeting_minutes_subject', { meetingName: appContext.state.selectedList?.name });
            const bodyContent = t('mail_template.meeting_minutes_content', { baseUrl: config.web.baseUrl, meetingId: appContext.state.selectedList?.id, creator: appContext.state.selectedList.creator });
            const ccListContent = ccList ? `&cc=${ccList}` : '';
            const mailtoUrl = `mailto:${toList}?subject=${encodeURIComponent(subject)}${ccListContent}&body=${encodeURIComponent(bodyContent)}`;

            // Open the mail client
            window.location.href = mailtoUrl;
        }
    }

    const handleDelete = (id: GridRowId) => () => {
        const item = items.find(r => r.id == id.toString());
        const newItems = items.filter((row) => row.id !== item.id);
        publishMeetingWithUsers(newItems, MeetingStatus.Published);
        setItems(newItems);
    };

    const getActions = (id: GridRowId, row: GridValidRowModel) => {
        return [(
            <GridActionsCellItem
                icon={<TooltipIcon titleKey={'general.delete'} icon={<DeleteIcon />} />}
                label="Delete"
                onClick={handleDelete(id)}
                sx={{
                    color: 'primary.main',
                }}
            />
        )];
    }

    const columns: GridColDef[] = [
        { field: 'email', headerName: appContext.state.selectedList?.status === MeetingStatus.Published ? 'library.viewers' : 'review.email', width: 300, editable: false },
        appContext.state.selectedList?.status !== MeetingStatus.Published ?
            {
                field: 'notification', headerName: 'review.notification', headerAlign: 'center', width: 170, editable: false,
                renderCell: (params) =>
                    <ToggleButtonGroup
                        value={params.row.notification}
                        exclusive
                        onChange={(
                            event: React.MouseEvent<HTMLElement>,
                            selection: string | null,
                        ) => handleToggle(event, selection, params)}
                        aria-label={t('review.notification_label')}
                    >
                        <ToggleButton size="small" value="to" aria-label="To" color='primary'>
                            <ForwardToInboxIcon />
                        </ToggleButton>
                        <ToggleButton size="small" value="cc" aria-label="CC" color='primary'>
                            <ClosedCaptionIcon />
                        </ToggleButton>
                        <ToggleButton size="small" value="x" aria-label="X" color='error'>
                            <CancelIcon />
                        </ToggleButton>
                    </ToggleButtonGroup>
            } :
            {
                field: 'actions',
                type: 'actions',
                width: 170,
                headerName: 'general.delete',
                cellClassName: 'actions',
                getActions: ({ id, row }) => getActions(id, row)
            }
    ];

    const translatedColumns: GridColDef[] = columns.map(translate);

    const handleToggle = (
        event: React.MouseEvent<HTMLElement>,
        selected: string | null,
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
    ) => {
        event.preventDefault();
        params.row.notification = selected;
        params.api.updateRows([{ id: params.row.id, data: params.row }]);
        processRowUpdate(params.row);
    };

    const handleNewParticipantInput = (value: string) => {
        setNewParticipant(value);
    }

    const handleAddParticipant = (ev: React.KeyboardEvent) => {
        if (ev.key === "Enter") {
            ev.preventDefault();
            handleAddParticipantButtonClick();
        }
    }
    const handleAddParticipantButtonClick = () => {
        if (newParticipant.trim() !== "") {
            const newItems = [...items, { id: newParticipant.trim(), email: newParticipant.trim(), notification: "to" }];
            setItems(newItems);
            setNewParticipant("");
            if (appContext.state.selectedList?.status === MeetingStatus.Published) {
                publishMeetingWithUsers(newItems, MeetingStatus.Published);
            }
        }
    }
    return (
        <Box>
            {/* <CssBaseline /> */}
            <MeetingHeader meetingName={appContext.state.selectedList?.name}
                primaryBtnText={t(publishText)}
                disablePrimaryBtn={appContext.state.selectedList?.status === MeetingStatus.Published}
                onPrimaryBtnClick={() => setShowEmailDistributionChoice(appContext.state.selectedList?.id)}
            />

            <div style={{ height: '70vh', width: '100%' }}>
                <DataGrid rows={items}
                    columns={translatedColumns}
                    disableRowSelectionOnClick={true}
                    rowSelection={false}
                    processRowUpdate={processRowUpdate}
                />
            </div>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {/* <CssBaseline /> */}
                <TextField
                    value={newParticipant}
                    sx={{ width: '300px' }}
                    onChange={(ev) => handleNewParticipantInput(ev.target.value || "")}
                    onKeyDown={(ev) => handleAddParticipant(ev)}
                    placeholder={t('review.participant_placeholder')}
                ></TextField>
                <IconButton onClick={handleAddParticipantButtonClick}>
                    <PersonAddIcon />
                </IconButton>
            </Box>
            {showEmailDistributionChoice && (
                <>
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
                        <div className="bg-white p-8 rounded-lg w-96">
                            <p className="text-base leading-normal mb-4">{t('library.distribute_email')}</p>
                            <div className="flex justify-center space-x-4 pt-4">

                                <Button variant="outlined" onClick={() => {
                                    navigate(`/library/${showEmailDistributionChoice}`, { state: { blockPrevious: true } });
                                    publishAsync(false);
                                    setShowEmailDistributionChoice(undefined);
                                }}>
                                    <Typography variant="button" display="block">
                                        {t('button.no')}
                                    </Typography>
                                </Button>
                                <Button variant="contained" onClick={() => {
                                    navigate(`/library/${showEmailDistributionChoice}`, { state: { blockPrevious: true } });
                                    publishAsync(true);
                                    setShowEmailDistributionChoice(undefined);
                                }}>
                                    <Typography variant="button" display="block">
                                        {t('button.yes')}
                                    </Typography>
                                </Button>

                            </div>
                        </div>
                    </div>
                </>
            )}
        </Box>
    );
};
