// import { Image, getTheme, IconButton, IIconProps, IStackStyles, mergeStyles, Persona, PersonaSize, Stack, Text, IImageProps, ImageFit } from '@fluentui/react';
import React, { FC, ReactElement, useContext, useEffect, useState } from 'react';
import { useMsal } from "@azure/msal-react";
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
// import MenuIcon from '@mui/icons-material/Menu';
import { ReactComponent as LeftPanelOpen } from "../icons/left_panel_open.svg";
import { ReactComponent as LeftPanelClose } from "../icons/left_panel_close.svg";
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { IPublicClientApplication } from '@azure/msal-browser';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Check from '@mui/icons-material/Check';
import Menu from '@mui/material/Menu';
import { Paper, Popover, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { isDefaultDomain, isEfesoDomain, isRtDomain } from '../config';
import { Context } from '../components/context';
import { AppContext } from '../models/applicationState';
import { UserRole } from '../models/user';

interface HeaderProps {
    open: boolean,
    toggleDrawer: () => void,
    openProjectPanel: boolean,
    showProjectPanel?: boolean,
    toggleProjectPanel: () => void
}

function signOutClickHandler(instance: IPublicClientApplication) {
    const logoutRequest = {
        account: instance.getActiveAccount(),
    };
    instance.logoutRedirect(logoutRequest);
}

function DenseMenu() {
    const { instance } = useMsal();
    const { t, i18n } = useTranslation();
    const [lng, setLng] = useState(i18n.language || window.localStorage.i18nextLng);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeLanguage = (selection: string) => {
        // console.log("current language: " + lng);
        // console.log("current i18n: " + i18n.language);
        // console.log("current local storage value: " + window.localStorage.i18nextLng);
        i18n.changeLanguage(selection);
        setLng(selection);
    }

    const style = {
        width: 700,
        bgcolor: '#fff',
        p: 4,
    };

    return (
        // <Paper sx={{ width: 160 }}>
        <div>
            <MenuList dense>
                <MenuItem
                    selected={lng === 'en'}
                    onClick={() => changeLanguage('en')}>
                    {lng === 'en' ?
                        <>
                            <ListItemIcon>
                                <Check />
                            </ListItemIcon>
                            {t('label.lang_en')}
                        </>
                        :
                        <ListItemText inset>
                            {t('label.lang_en')}
                        </ListItemText>
                    }
                </MenuItem>
                <MenuItem
                    selected={lng === 'de'}
                    onClick={() => changeLanguage('de')}>
                    {lng === 'de' ?
                        <>
                            <ListItemIcon>
                                <Check />
                            </ListItemIcon>
                            {t('label.lang_de')}
                        </>
                        :
                        <ListItemText inset>
                            {t('label.lang_de')}
                        </ListItemText>
                    }
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleClick}>
                    <ListItemText>{t('button.about')}</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => signOutClickHandler(instance)}>
                    <ListItemText>{t('button.logout')}</ListItemText>
                </MenuItem>
            </MenuList>
            <Popover
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                role={undefined}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
            >
                <Paper>
                    <Box sx={style}>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <p>
                                {t('about.title')}
                            </p>
                            <a href="https://www.leadwithpinnacle.com/">leadwithpinnacle.com</a>
                            <br />
                            {t('about.copyright')}
                        </Typography>
                    </Box>
                </Paper>
            </Popover>
        </div>
        // </Paper>
    );
}

function PositionedMenu() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { t } = useTranslation();
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                color="inherit"
                aria-label={t('label.settings')}
                onClick={handleClick}
                id="demo-positioned-button"
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
            // edge="end"
            // sx={{
            //     // marginRight: 0,
            //     // ...(open && { display: 'none' }),
            // }}
            >
                <SettingsIcon />
            </IconButton>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            // anchorOrigin={{
            //     vertical: 'bottom',
            //     horizontal: 'left',
            // }}
            // transformOrigin={{
            //     vertical: 'top',
            //     horizontal: 'left',
            // }}
            >
                <DenseMenu />
            </Menu>
        </div>
    );
}

const hostName = window.location.hostname;

const MainHeader: FC<HeaderProps> = (props: HeaderProps): ReactElement => {
    const { instance } = useMsal();
    const { t } = useTranslation();
    const appContext = useContext<AppContext>(Context);
    const userFullName = (appContext.state.invoker?.lastName && appContext.state.invoker?.firstName) ? (appContext.state.invoker?.firstName + ' ' + appContext.state.invoker?.lastName) : instance.getActiveAccount()?.name;

    const toolbarStyle = {
        pr: '24px', // keep right padding when drawer closed    
    }
    if (isDefaultDomain()) {
        toolbarStyle['backgroundColor'] = appContext.state.invoker?.role === UserRole.Actor ? '#CBB6A5' : '#242C63';
    }

    return (
        <Toolbar
            sx={toolbarStyle}
        >
            <IconButton
                edge="start"
                color="inherit"
                aria-label={t('label.menu_drawer')}
                onClick={props.toggleDrawer}
                sx={{
                    marginRight: '36px',
                    // ...(open && { display: 'none' }),
                }}
            >
                {props.open ?
                    <LeftPanelClose /> : <LeftPanelOpen />}
            </IconButton>
            {isDefaultDomain() &&
                <Box
                    component="img"
                    sx={{
                        height: 28,
                        marginRight: 1
                    }}
                    alt="Pinnacle logo"
                    src='/icon_gray.svg'
                />
            }

            <Box
                component="img"
                sx={!isDefaultDomain() ? {
                    height: 38,
                } : {
                    height: 18,
                }}
                alt="Pinnacle"
                src={isEfesoDomain() ?
                    '/efeso.png'
                    : (isRtDomain() ?
                        '/rt.png' :
                        '/logo_white.svg')
                }
            />

            <Box sx={{ flexGrow: 1 }} />

            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Avatar sx={{ width: 24, height: 24 }} >
                    {/* <AccountCircleIcon/> */}
                </Avatar>
                <Tooltip title={instance.getActiveAccount()?.username}>
                    <Typography variant="subtitle2" sx={{ marginRight: 1 }}>
                        {userFullName}
                    </Typography>
                </Tooltip>

            </Box>

            <PositionedMenu />
            {props.showProjectPanel &&
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <Typography variant="subtitle2" sx={{ marginLeft: 1, marginRight: 1 }}>
                        {t('toolbar.projects')}
                    </Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label={t('label.project_drawer')}
                        onClick={props.toggleProjectPanel}
                    // sx={{
                    //     marginRight: '36px',
                    //     // ...(open && { display: 'none' }),
                    // }}
                    >
                        {props.openProjectPanel ?
                            <KeyboardDoubleArrowRightIcon /> : <KeyboardDoubleArrowLeftIcon />}
                    </IconButton>
                </Box>
            }
        </Toolbar>
    );
}

export default MainHeader;