import { Box, Button, ButtonProps, Checkbox, CssBaseline, Grid, Typography, styled } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Context } from "../../components/context";
import { withApplicationInsights } from "../../components/telemetry";
import { AppContext } from "../../models/applicationState";
import { MeetingItemRepresentation, contentColumn, convertToRepresentation, createdDateColumn, dueDateColumn, meetingNameColumn, statusColumn, statusTranslatedLabelOptions } from "../../models/meetingItemRepresentation";
import { DataGrid, GridActionsCellItem, GridCellParams, GridColDef, GridRowId } from "@mui/x-data-grid";
import * as actorDashboardActions from '../../actions/actorDashboardActions';
import * as creatorDashboardActions from '../../actions/creatorDashboardActions';
import ItemStatus from "../../components/itemStatus";
import { Meeting, MeetingItemStatus, MeetingStatus, UserRole } from "../../models";
import { dateColumn, libraryIsPublishedColumn, nameColumn } from "../../models/meetingRepresentation";
import { TooltipIcon } from "../../components/tooltipIcon";
import { ReactComponent as PublishedIcon } from "../../icons/mark_email_read_fill.svg";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { StyledDataGrid } from "../../components/styledGrid";

const Landing = () => {

    const ACTOR_HIGHLIGHTED_ITEMS_KEY = "actorHighlightedItems"
    const CREATOR_HIGHLIGHTED_ITEMS_KEY = "creatorHighlightedItems"
    const appContext = useContext<AppContext>(Context);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [actorItems, setActorItems] = useState<MeetingItemRepresentation[]>([]);
    const [creatorItems, setCreatorItems] = useState<MeetingItemRepresentation[]>([]);
    const [actorHighlightedItemIds, setActorHighlightedItemIds] = useState<string[]>(JSON.parse(window.sessionStorage.getItem(ACTOR_HIGHLIGHTED_ITEMS_KEY)) || []);
    const [creatorHighlightedItemIds, setCreatorHighlightedItemIds] = useState<string[]>(JSON.parse(window.sessionStorage.getItem(CREATOR_HIGHLIGHTED_ITEMS_KEY)) || []);
    const [myNewTasks, setMyNewTasks] = useState<MeetingItemRepresentation[]>([]);
    const [staffCompletedTasks, setStaffCompletedTasks] = useState<MeetingItemRepresentation[]>([]);
    const [staff7DayTasks, setStaff7DayTasks] = useState<MeetingItemRepresentation[]>([]);
    const [my7DayTasks, setMy7DayTasks] = useState<MeetingItemRepresentation[]>([]);
    const [meetings, setMeetings] = useState(appContext.state.lists || []);
    const [isCreator, setIsCreator] = useState(false);

    useEffect(() => {
        setIsCreator(appContext.state.invoker?.role !== UserRole.Actor);
    }, [appContext.state.invoker, navigate]);

    const sortMeetings = (meetings: Meeting[]) => {
        return [...(meetings || [])]?.sort((a, b) => {
            if (a.createdDate < b.createdDate) return 1;
            if (a.createdDate > b.createdDate) return -1;
            return 0;
        });
    }

    const sortItems = (items: MeetingItemRepresentation[]) => {
        return [...(items || [])]?.sort((a, b) => {
            if (a.dueDate < b.dueDate) return 1;
            if (a.dueDate > b.dueDate) return -1;
            return 0;
        });
    }

    useEffect(() => {
        setMeetings(sortMeetings(appContext.state.lists));
    }, [appContext.state.lists, navigate]);

    useEffect(() => {
        const existingStorage = window.sessionStorage.getItem(ACTOR_HIGHLIGHTED_ITEMS_KEY);
        if (existingStorage) {
            const existingHighlightedItems = JSON.parse(existingStorage) as string[];
            if (existingHighlightedItems?.length !== actorHighlightedItemIds?.length) {
                const stringToStore = JSON.stringify(actorHighlightedItemIds);
                window.sessionStorage.setItem(ACTOR_HIGHLIGHTED_ITEMS_KEY, stringToStore);
            }
        } else {
            window.sessionStorage.setItem(ACTOR_HIGHLIGHTED_ITEMS_KEY, JSON.stringify(actorHighlightedItemIds));
        }
    }, [actorHighlightedItemIds]);

    async function storeActorDiffs() {
        const response = await actorDashboardActions.listDiffs();
        if (response.length) {
            const highlightsToStore = [...actorHighlightedItemIds];
            const newHighlightedIds = response.map(i => i.id);
            if (newHighlightedIds) {
                for (const highlightedItemId of newHighlightedIds) {
                    if (!highlightsToStore.includes(highlightedItemId)) {
                        highlightsToStore.push(highlightedItemId);
                    }
                }
                setActorHighlightedItemIds(highlightsToStore);
            }
        }
    }

    useEffect(() => {
        const existingStorage = window.sessionStorage.getItem(CREATOR_HIGHLIGHTED_ITEMS_KEY);
        if (existingStorage) {
            const existingHighlightedItems = JSON.parse(existingStorage) as string[];
            if (existingHighlightedItems?.length !== creatorHighlightedItemIds?.length) {
                const stringToStore = JSON.stringify(creatorHighlightedItemIds);
                window.sessionStorage.setItem(CREATOR_HIGHLIGHTED_ITEMS_KEY, stringToStore);
            }
        } else {
            window.sessionStorage.setItem(CREATOR_HIGHLIGHTED_ITEMS_KEY, JSON.stringify(creatorHighlightedItemIds));
        }
    }, [creatorHighlightedItemIds]);

    async function storeCreatorDiffs() {
        const response = await creatorDashboardActions.listDiffs();
        if (response.length) {
            const highlightsToStore = [...creatorHighlightedItemIds];
            const newHighlightedIds = response.map(i => i.id);
            if (newHighlightedIds) {
                for (const highlightedItemId of newHighlightedIds) {
                    if (!highlightsToStore.includes(highlightedItemId)) {
                        highlightsToStore.push(highlightedItemId);
                    }
                }
                setCreatorHighlightedItemIds(highlightsToStore);
            }
        }
    }

    async function fetchData() {
        storeActorDiffs();
        const actorResponse = await actorDashboardActions.list();
        setActorItems(convertToRepresentation(actorResponse).filter(i => !i.isSnapshot));
        storeCreatorDiffs();
        const creatorResponse = await creatorDashboardActions.list();
        setCreatorItems(convertToRepresentation(creatorResponse).filter(i => !i.isSnapshot));
    }

    const translate = (col: GridColDef) => {
        if (col.field.startsWith("progress")) return col;
        return { ...col, headerName: t(col.headerName) };
    };

    useEffect(() => {
        setMyNewTasks(actorItems.filter(i => (actorHighlightedItemIds || []).includes(i.id)));
        setMy7DayTasks(sortItems(actorItems.filter(i =>
            i.data?.dueDate &&
            new Date(i.data.dueDate) < new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000) &&
            new Date(i.data.dueDate) > new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000))));
    }, [actorItems]);

    useEffect(() => {
        setStaffCompletedTasks(sortItems(creatorItems.filter(i => i.actor !== appContext.state.invoker?.email && i.status === MeetingItemStatus.Completed)));
        setStaff7DayTasks(sortItems(creatorItems.filter(i =>
            i.actor !== appContext.state.invoker?.email &&
            i.data?.dueDate &&
            new Date(i.data.dueDate) < new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000) &&
            new Date(i.data.dueDate) > new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000))));
    }, [creatorItems]);

    useEffect(() => {
        fetchData();
    }, [navigate]);

    const actorColumns: GridColDef[] = [
        createdDateColumn,
        meetingNameColumn,
        { ...contentColumn, flex: 0.6 },
        { ...dueDateColumn, editable: false },
        {
            ...statusColumn,
            type: 'singleSelect',
            valueFormatter: (value: string) => t(`item_status.${value}`),
            valueOptions: statusTranslatedLabelOptions(t),
            editable: false,
            renderCell: (params) => (
                <ItemStatus status={params.row.status} lastModifiedBy={params.row.statusLastUpdatedBy}></ItemStatus>
            ),
        }
    ];

    const creatorColumns: GridColDef[] = [
        meetingNameColumn,
        { ...contentColumn, flex: 0.6 },
        { field: 'actor', headerName: 'item.actor', editable: false },
        { ...dueDateColumn, editable: false },
        {
            ...statusColumn, editable: false,
            type: 'singleSelect',
            valueFormatter: (value: string) => t(`item_status.${value}`),
            valueOptions: statusTranslatedLabelOptions(t),
            renderCell: (params: GridCellParams) => (
                <ItemStatus status={params.row.status} lastModifiedBy={params.row.statusLastUpdatedBy}></ItemStatus>
            ),
        }
    ];

    const onNavigate = (meeting: Meeting) => {
        if (meeting) {
            if (meeting.status === MeetingStatus.Published) {
                navigate(`/library/${meeting.id}`);
            } else {
                navigate(`/library`);
            }
        }
    };

    const navigateAction = (id: GridRowId, row: Meeting) => {
        const handleNavigate = (_id: GridRowId) => () => {
            onNavigate(row);
        };

        return (
            <GridActionsCellItem
                icon={<TooltipIcon titleKey={'general.open'} icon={<ChevronRightIcon />} />}
                label="Open"
                onClick={handleNavigate(id)}
                sx={{
                    color: 'primary.main',
                }}
            />);
    }

    const meetingLibraryColumns: GridColDef[] = [
        { ...dateColumn, editable: false },
        { ...nameColumn, editable: false, flex: 0.6 },
        {
            ...libraryIsPublishedColumn as GridColDef,
            flex: 0.2,
            renderCell: (params) => (
                <Box>
                    {params.row.status === MeetingStatus.Published
                        && (
                            <Checkbox
                                disabled
                                checkedIcon={<TooltipIcon titleKey={'library.published.status'} icon={<PublishedIcon />} />}
                                checked={true} />
                        )}
                </Box>
            ),
        },
        {
            field: 'actions',
            type: 'actions',
            flex: 0.2,
            cellClassName: 'actions',
            getActions: ({ id, row }) => [navigateAction(id, row)]
        }

    ];

    const LandingButton = styled(Button)<ButtonProps>(({ theme }) => ({
        width: 250,
        backgroundColor: theme.palette.action.selected,
        variant: "contained",
        '&:hover': {
            backgroundColor: theme.palette.action.focus,
        },
    }));

    return (
        <Box>
            <CssBaseline />
            <Box sx={{ mt: 1 }}>
                <Grid container
                    // direction="column"
                    height="90vh"
                    spacing={1} 
                    columnSpacing={3}>
                    <Grid item xs={6} >
                        <Box sx={{ display: 'flex', alignItems: "center", mt: 2}}>
                            <Typography variant="h6" >
                                {myNewTasks?.length ? t('leadership.actor_dashboard_new') : t('leadership.empty_actor_dashboard_new')}
                            </Typography>
                            <Box sx={{ flexGrow: 1 }} />
                            <LandingButton  onClick={() => navigate('/dashboard/actor')}>
                                <Typography variant="button" display="block">
                                    {t('menu.actor_dashboard')}
                                </Typography>
                            </LandingButton>
                        </Box>
                    </Grid>
                    {isCreator && <Grid item xs={6} >
                        <Box sx={{ display: 'flex', alignItems: "center", mt: 2 }}>
                            <Typography variant="h6" >
                                {staffCompletedTasks?.length ? t('leadership.creator_dashboard_completed') : t('leadership.empty_creator_dashboard_completed')}
                            </Typography>
                            <Box sx={{ flexGrow: 1 }} />
                            <LandingButton onClick={() => navigate('/dashboard/creator')}>
                                <Typography variant="button" display="block">
                                    {t('menu.creator_dashboard')}
                                </Typography>
                            </LandingButton>
                        </Box>
                    </Grid>}
                    {!isCreator && <Grid item xs={6} >
                        <Box sx={{ display: 'flex', alignItems: "center", mt: 2 }}>
                            <Typography variant="h6" >
                                {my7DayTasks?.length ? t('leadership.actor_dashboard_7days') : t('leadership.empty_actor_dashboard_7days')}
                            </Typography>
                            <Box sx={{ flexGrow: 1 }} />
                            <LandingButton onClick={() => navigate('/dashboard/actor')}>
                                <Typography variant="button" display="block">
                                    {t('menu.actor_dashboard')}
                                </Typography>
                            </LandingButton>
                        </Box>
                    </Grid>}
                    <Grid item xs={6} >
                        <Box sx={{ backgroundColor: "background.default", height: '37vh' }}>
                            <StyledDataGrid
                                rows={myNewTasks}
                                columns={actorColumns.map(translate)}
                                autoHeight={false}
                                hideFooter={true}
                            />
                        </Box>
                    </Grid>
                    {isCreator && <Grid item xs={6} >
                        <Box sx={{ backgroundColor: "background.default", height: '37vh' }}>
                            <StyledDataGrid
                                rows={staffCompletedTasks}
                                columns={creatorColumns.map(translate)}
                                autoHeight={false}
                                hideFooter={true}
                            />
                        </Box>
                    </Grid>
                    }
                    {!isCreator && <Grid item xs={6} >
                        <Box sx={{ backgroundColor: "background.default", height: '37vh' }}>
                            <StyledDataGrid
                                rows={my7DayTasks}
                                columns={actorColumns.map(translate)}
                                autoHeight={false}
                                hideFooter={true}
                            />
                        </Box>
                    </Grid>
                    }
                    <Grid item xs={6} >
                        <Box sx={{ display: 'flex', alignItems: "center", mt: 2 }}>
                            <Typography variant="h6" >
                                {meetings?.length ? t('leadership.meeting_library_new') : t('leadership.empty_meeting_library_new')}
                            </Typography>
                            <Box sx={{ flexGrow: 1 }} />
                            <LandingButton onClick={() => navigate('/library')}>
                                <Typography variant="button" display="block">
                                    {t('menu.meeting_minutes_library')}
                                </Typography>
                            </LandingButton>
                        </Box>
                    </Grid>
                    {isCreator && <Grid item xs={6} >
                        <Box sx={{ display: 'flex', alignItems: "center", mt: 2 }}>
                            <Typography variant="h6" >
                                {staff7DayTasks?.length ? t('leadership.creator_dashboard_7days') : t('leadership.empty_creator_dashboard_7days')}
                            </Typography>
                            <Box sx={{ flexGrow: 1 }} />
                            <LandingButton onClick={() => navigate('/dashboard/creator')}>
                                <Typography variant="button" display="block">
                                    {t('menu.creator_dashboard')}
                                </Typography>
                            </LandingButton>
                        </Box>
                    </Grid>}
                    {!isCreator && <Grid item xs={6} >
                        <Box sx={{ display: 'flex', alignItems: "center" }}>
                            <Box sx={{ flexGrow: 1 }} />
                        </Box>
                    </Grid>}
                    <Grid item xs={6} >
                        <Box sx={{ backgroundColor: "background.default", height: '37vh' }}>
                            <StyledDataGrid
                                rows={meetings}
                                columns={meetingLibraryColumns.map(translate)}
                                autoHeight={false}
                                hideFooter={true}
                            />
                        </Box>
                    </Grid>
                    {isCreator && <Grid item xs={6} >
                        <Box sx={{ backgroundColor: "background.default", height: '37vh' }}>
                            <StyledDataGrid
                                rows={staff7DayTasks}
                                columns={creatorColumns.map(translate)}
                                autoHeight={false}
                                hideFooter={true}
                            />
                        </Box>
                    </Grid>
                    }
                </Grid>
            </Box>

        </Box >
    );
};

export default withApplicationInsights(Landing, "Landing");
