import { FC, ReactElement, useContext, useEffect, useState } from "react";
import { createBlobInContainer, deleteBlobFromContainer, Attachment, createAttachment, getAttachments, deleteAttachment } from "../services/attachmentService";
import { Box, Button, CircularProgress, IconButton, TextField, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// import FileCopyIcon from '@mui/icons-material/FileCopy';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import { DataGrid, GridActionsCellItem, GridColDef, GridRowId, GridToolbarContainer } from "@mui/x-data-grid";
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CloseIcon from '@mui/icons-material/Close';
// import FilePresentIcon from '@mui/icons-material/FilePresent';
import { useTranslation } from "react-i18next";
import { randomId } from "@mui/x-data-grid-generator";
import React from "react";
import { Context } from "./context";
import { AppContext } from "../models/applicationState";
import { UserRole } from "../models/user";
import ConfirmationPanel from "./confirmationPanel";

interface AttachmentsPaneProps {
    height: string
    allowModifications: boolean;
    meetingId?: string;
    itemId?: string;
    onClose?: () => Promise<void> | void;
    onAttachmentsCountChanged?: (count: number, meetingId?: string, itemId?: string) => Promise<void> | void;
    uploadPrefix?: string
    contextDisplayName?: string
}

export const AttachmentsPane: FC<AttachmentsPaneProps> = (props: AttachmentsPaneProps): ReactElement => {
    const appContext = useContext<AppContext>(Context);

    const [attachmentsList, setAttachmentsList] = useState<Attachment[]>([]);
    const [fileSelected, setFileSelected] = useState<File | null>();
    const [fileUploaded, setFileUploaded] = useState<string>('');
    const [uploading, setUploading] = useState<boolean>(false);
    const [inputKey, setInputKey] = useState(Math.random().toString(36));
    const [attachmentToDelete, setAttachmentToDelete] = useState(undefined);
    const { t } = useTranslation();

    useEffect(() => {
        props.onAttachmentsCountChanged(attachmentsList?.length, props.meetingId, props.itemId);
    }, [attachmentsList]);

    useEffect(() => {
        getAttachments(props.meetingId, props.itemId).then((list: any) => {
            setAttachmentsList(list);
        })
    }, [fileUploaded]);

    useEffect(() => {
        onFileUpload();
    }, [fileSelected]);

    const onFileChange = (event: any) => {
        setFileSelected(event.target.files[0]);
    };

    const onFileUpload = async () => {
        if (fileSelected && fileSelected?.name) {
            setUploading(true);

            // UPLOAD TO AZURE BLOB
            const blob = await createBlobInContainer(props.uploadPrefix, fileSelected, props.meetingId, props.itemId);
            setAttachmentsList([...attachmentsList, blob]);
            setUploading(false);
            await createAttachment(blob, props.meetingId, props.itemId);

            // reset state
            setFileSelected(null);
            setFileUploaded(fileSelected.name);
            setInputKey(Math.random().toString(36));
        }
    };

    const handleDeleteClick = (id: GridRowId, deleted: Attachment) => async () => {
        setAttachmentToDelete({ ...deleted, id: id });
    };

    const handleDeleteConfirmed = async (deleted: Attachment) => {
        setAttachmentsList(attachmentsList.filter((row) => row.id !== deleted.id));
        if (!deleted.external) {
            await deleteBlobFromContainer(props.meetingId, deleted.name, props.itemId);
        }
        await deleteAttachment(deleted, props.meetingId, props.itemId);
    };

    const translate = (col: GridColDef) => {
        return { ...col, headerName: t(col.headerName) };
    };

    const nameColumn: GridColDef<any> = { field: 'displayName', headerName: 'attachment_pane.displayName', width: 400, editable: false }

    // const onDownload = (displayName: string, url: string) => () => {
    //     const link = document.createElement("a");
    //     link.download = displayName;
    //     link.href = url;
    //     link.click();
    //   };

    const downloadColumn: GridColDef<any> = {
        field: 'download',
        type: 'actions',
        headerName: 'attachment_pane.download',
        width: 100,
        cellClassName: 'actions',
        renderCell: (params) => params.row.external ? (
            <IconButton href={params.row.url.startsWith("http") ? params.row.url : "https://" + params.row.url} target="_blank">
                <AttachFileOutlinedIcon />
            </IconButton >
        ) : (
            <IconButton href={params.row.url} target="_blank" download>
                <FileDownloadOutlinedIcon />
            </IconButton >
        )
    }

    const deleteColumn: GridColDef<any> = {
        field: 'actions',
        type: 'actions',
        headerName: 'attachment_pane.delete',
        width: 100,
        cellClassName: 'actions',
        getActions: ({ id, row }) => {
            return [
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={handleDeleteClick(id, row)}
                    color="inherit"
                    disabled={!props.allowModifications || row.id.includes('-') || (appContext.state.invoker.role === UserRole.Actor && row.creatorEmail !== appContext.state.invoker?.email)}
                />
            ];
        }
    }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const hideConfirmationModal = () => {
        setAttachmentToDelete(false);
    };

    const submitDelete = () => {
        handleDeleteConfirmed(attachmentToDelete);
        setAttachmentToDelete(undefined);
    };

    const CustomToolbar: FC = (): ReactElement => {
        const [linkUrl, setLinkUrl] = useState("");
        const [linkName, setLinkName] = useState("");

        const onLinkAdded = async () => {
            const attachment = {
                id: randomId(),
                url: linkUrl,
                name: linkName || linkUrl,
                displayName: linkName || linkUrl,
                external: true
            };
            const created = await createAttachment(attachment, props.meetingId, props.itemId);
            setAttachmentsList([...attachmentsList, created]);
            setLinkName("");
            setLinkUrl("");
            // await createBlobInContainer(props.uploadPrefix, fileSelected, props.meetingId, props.itemId);
        };

        return (<GridToolbarContainer>
            <TextField
                id="link_name"
                variant="standard"
                value={linkName}
                disabled={!props.allowModifications}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setLinkName(event.target.value || "");
                }}
                placeholder={t('attachment_pane.link_name')}
            ></TextField>
            <TextField
                id="link_url"
                variant="standard"
                value={linkUrl}
                disabled={!props.allowModifications}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setLinkUrl(event.target.value || "");
                }}
                placeholder={t('attachment_pane.link_url')}
            ></TextField>
            <Button variant="contained" startIcon={<AttachFileOutlinedIcon />} onClick={onLinkAdded} disabled={!linkUrl || !props.allowModifications}>
                <Typography variant="button" display="block">
                    {t('button.link')}
                </Typography>
            </Button>
            <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                onClick={onFileUpload}
                disabled={uploading || !props.allowModifications}
            >
                {t('button.upload')}
                <VisuallyHiddenInput type="file" onChange={onFileChange} key={inputKey || ''} />
            </Button>
            <Box style={{ display: (uploading ? 'inline' : 'none') }}>
                <CircularProgress size={30} />
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Button
                variant="outlined"
                tabIndex={-1}
                startIcon={<CloseIcon />}
                onClick={props.onClose}
            >
                {t('button.close')}
            </Button>
        </GridToolbarContainer>);
    }

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="bg-white p-8 rounded-lg">
                <div className="flex justify-center space-x-4 pt-4">
                    <div style={{ height: '70vh', width: '100%' }}>
                        <Box>
                            <div style={{ height: props.height, width: '100%' }}>
                                <DataGrid rows={attachmentsList}
                                    columns={[nameColumn, downloadColumn, deleteColumn].map(translate)}
                                    slots={{ toolbar: CustomToolbar }}
                                />
                            </div>
                        </Box>
                    </div>
                </div>
            </div>
            {attachmentToDelete &&
                <ConfirmationPanel
                    showModal={!!attachmentToDelete}
                    confirmModal={submitDelete}
                    hideModal={hideConfirmationModal}
                    entry={attachmentToDelete}
                    type={"attachment"}
                    action="delete"
                />}
        </div>
    );
}